<template>
  <div>
    <div class="zhezao">
      <div id="localtracks" class="longvideo">
        
      </div>
      <div id="remotetracks" class="op_video">
        
      </div>
      <div class="title">
          <span><router-link to="/zhengxie/meeting" style="color:#Fff"><van-icon name="cross" /></router-link></span>
          <span class="van-ellipsis">发起的视频会议</span>
        </div>
        <div class="operation">
          <div class="round_on">
            <button class="video_on" @click="Microphone">静音</button>
            <button class="video_on" style="left: 38%" @click="Microphone">
              摄像头
            </button>
            <button class="video_on" style="left: 76%" @click="Microphone">
              邀请
            </button>
          </div>
          <van-button
            @click="back"
            class="back"
            color="linear-gradient(to right, #ff6034, #ee0a24)"
          >
            离 开 会 议
          </van-button>
        </div>
    </div>
  </div>
</template>
<script>
import * as QNRTC from "@/api/pili-rtc-web";
// import opvideo from "./components/video";
export default {
  components: {},
  data() {
    return {
      username: "", // 用户名
      userphone: "", // 手机号
      isoperation: false, // 是否打开操作按钮
      isremotetracks: false, // 是否展示别人的
      myRoom: 0,
      ismit: true, // 话筒切换样式
      localTracks: [],
      isScreenshar: true, // 屏幕共享切换样式
      roomcode: "",
      // 加入房间token
      codetokenroom:
        "jPwWxsqPwIVqtHTCc_X7dm7lVTVxJZag3772Ry3H:FSmnXp1OL-i1sWEFEZ6lfzkhEv4=:eyJhcHBJZCI6ImZ2aWUxd2U0NyIsImV4cGlyZUF0IjoxNjYyMTg4Mzg2LCJwZXJtaXNzaW9uIjoidXNlciIsInJvb21OYW1lIjoiZGV4aWFuIiwidXNlcklkIjoiemVuZ2MifQ==",
    };
  },
  created() {
    this.myRoom = new QNRTC.TrackModeSession();
    this.getroomtoken();
  },
  methods: {
    // 初始化
    async getroomtoken() {
      await this.myRoom.joinRoomWithToken(this.codetokenroom);
      await this.publish(this.myRoom);
      this.autoSubscribe(this.myRoom);
      this.myRoom.on("user-join", (res) => {
        console.log("监听", res);
      });
    },
    // 采集并发布本地的音视频轨
    async publish(myRoom) {
      // 我们打开了 3 个参数，即采集音频，采集视频，采集屏幕共享。
      // 这个函数会返回一个列表，列表中每一项就是一个音视频轨对象
      this.localTracks = await QNRTC.deviceManager.getLocalTracks({
        audio: { enabled: false, tag: "audio" },
        video: { enabled: false, tag: "video" },
        screen: { enabled: false, tag: "screen" },
      });
      // 将刚刚的 Track 列表发布到房间中
      await myRoom.publish(this.localTracks);
      // 获取页面上的一个元素作为播放画面的父元素
      const localElement = document.getElementById("localtracks");
      // 遍历本地采集的 Track 对象
      for (const localTrack of this.localTracks) {
        // 如果这是麦克风采集的音频 Track，我们就不播放它。
        if (localTrack.info.tag === "audio") continue;
        // if (localTrack.info.tag === "video") continue;
        // 调用 Track 对象的 play 方法在这个元素下播放视频轨
        this.isoperation = true;
        localTrack.play(localElement, true);
      }
    },

    // 订阅远端发布的音视频轨
    async subscribe(myRoom, trackInfoList) {
      // 通过传入 trackId 调用订阅方法发起订阅，成功会返回相应的 Track 对象，也就是远端的 Track 列表了
      const remoteTracks = await myRoom.subscribe(
        trackInfoList.map((info) => info.trackId)
      );
      // console.log(remoteTracks)
      // 选择页面上的一个元素作为父元素，播放远端的音视频轨
      const remoteElement = document.getElementById("remotetracks");
      // 遍历返回的远端 Track，调用 play 方法完成在页面上的播放
      for (const remoteTrack of remoteTracks) {
        remoteTrack.play(remoteElement);
      }
    },

    autoSubscribe(myRoom) {
      const that = this;
      const trackInfoList = myRoom.trackInfoList;
      console.log("room current trackInfo list", trackInfoList);
      // 调用我们刚刚编写的 subscribe 方法
      // 注意这里我们没有使用 async/await，而是使用了 Promise，大家可以思考一下为什么
      that
        .subscribe(myRoom, trackInfoList)
        .then(() => console.log("subscribe success!"))
        .catch((e) => console.error("subscribe error", e));
      // 就是这样，就像监听 DOM 事件一样通过 on 方法监听相应的事件并给出处理函数即可
      // 添加事件监听，当房间中出现新的 Track 时就会触发，参数是 trackInfo 列表
      myRoom.on("track-add", (trackInfoList) => {
        console.log("get track-add event!", trackInfoList);
        that
          .subscribe(myRoom, trackInfoList)
          .then(() => console.log("subscribe success!"))
          .catch((e) => console.error("subscribe error", e));
      });
    },

    // 闭麦
    Microphone() {
      console.log(this.localTracks);
      switch (this.ismit) {
        case true:
          for (const remoteTrack of this.localTracks) {
            remoteTrack.setVolume(0);
          }
          break;
        case false:
          for (const remoteTrack of this.localTracks) {
            remoteTrack.setVolume(1);
          }
          break;
      }
      this.ismit = !this.ismit;
    },

    // 退出房间
    back() {
      this.myRoom.leaveRoom();
      // 遍历 tracks，逐个销毁释放
      for (const track of this.localTracks) {
        track.release();
      }
      this.$router.push("/zhengxie/meeting")
    },
  },
};
</script>
<style scoped>
.title {
  position: absolute;
  top: 20px;
  color: #fff;
}
.title span:nth-child(1) {
  font-size: 32px;
  margin-left: 15px;
}
.title span:nth-child(2) {
  position: absolute;
  top: 2px;
  width: 300px;
  padding-left: 5px;
  font-size: 20px;
}
.zhezao {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 1);
  overflow: hidden;
}
.op_video {
  z-index: 10;
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.op_video video:nth-child(2) {
  position: absolute;
  top: 0px;
  left: 0px;
  widows: 300px !important;
  height: 140px !important;
}
.round_on {
  z-index: 10;
  position: absolute;
  bottom: 120px;
  width: 65%;
  height: 65px;
  margin: 0px 17.5%;
}
.video_on {
  z-index: 10;
  position: absolute;
  width: 65px;
  height: 65px;
  border-radius: 50px;
  background: rgb(255, 255, 255, 0.3);
  color: #fff;
  border: none;
}
.back {
  display: block;
  z-index: 10;
  position: absolute;
  bottom: 50px;
  width: 60%;
  height: 45px;
  margin: 0px 20%;
  border-radius: 50px;
}
.video_icon {
  color: red;
  font-size: 32px;
  margin: 8px 10px;
}
.longvideo {
  z-index: 100;
  position: absolute;
  top: 0px;
  left: 0px;
}
.longvideo video{
  z-index: 100;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 300px;
  height: 300px;
}
</style>
